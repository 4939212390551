<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin:0;padding-left: 10px;">NeXa</h4>
        <span class="divider"></span>
        <span class="breadcrumb-text">Use Cases / Build an Argument</span>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon">keyboard_double_arrow_left</span>
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div class="d-flex justify-content-end cursor-pointer back" @click="goBack">
          <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
          <span style="margin: 0 0 0 8px;">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <loader v-if="loading"></loader>
        <div v-else>
          <section
            class="claims-container"
            v-for="(q, index) in filteredQuestions"
            :key="`questions-${q.id}`"
            :id="`q-${q.id}`"
          >
            <header class="claims-header">
              <div @click="toggleCollapse(index)" class="d-flex align-items-start">
                <div class="col-auto me-2">
                  <h6 class="text-nowrap mb-0" style="color: #0E4485 !important;">Follow up Question:</h6>
                </div>
                <div class="col">
                  <h6 class="question mb-0">{{ q.question }}</h6>
                </div>
              </div>

              <div class="action-buttons" v-if="q.answer !== null">
                <button
                  :disabled="isCopied[index]"
                  class="action-btn copy-btn"
                  tabindex="0"
                  @click="copyAnswer(q, index)"
                >
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5fc1aca50cb553e4e813d1e66a4f62c328c94fc60417f9c7fb438b58331a1a1?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                    alt="Copy content"
                    class="action-icon"
                  />
                  <span>{{ isCopied[index] ? "Copied" : "Copy" }}</span>
                </button>
                <b-dropdown
                  size="sm"
                  id="dropdown-right"
                  right
                  offset="-9"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  @show="toggleDropdown(index, true)"
                  @hide="toggleDropdown(index, false)"
                >
                  <template #button-content>
                    <button class="action-btn export-btn">
                      <span class="button-label">Export</span>
                      <img
                        :class="{ 'rotate-180': isDropdownOpen[index] }"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                        alt="Export Icon"
                        class="icon"
                      />
                    </button>
                  </template>
                  <div v-if="JSON.parse(q.answer).string">
                    <b-dropdown-item class="dropdown-text ms-1 me-1" @click="ExportData(JSON.parse(q.answer).string, q.question)"> 
                      <img class="me-2" src="./../../../assets/icon/pdf-icon.svg">
                      <span class="dropdown-text ">Export as PDF</span>
                    </b-dropdown-item>
                  </div>
                  <div v-else>
                    <b-dropdown-item class="ms-1 me-1 mb-1" @click="exportToPDF(q)">
                      <img class="me-2" src="./../../../assets/icon/pdf-icon.svg">
                      <span class="dropdown-text ">Export as PDF</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="">
                      <vue-excel-xlsx
                        tabindex="0"
                        :columns="headerTableFollowUpQuestion(q)"
                        :data="filteredTableFollowUpQuestion(q)"
                        :file-name="`Document Insight - Follow Up Question - ${q.question}`"
                        :file-type="'xlsx'"
                        :sheet-name="'sheetname'"
                      >
                      <img class="me-2" src="./../../../assets/icon/excel-icon.svg">
                      <span class="dropdown-text ">Export as Excel</span>
                      </vue-excel-xlsx>
                    </b-dropdown-item>

                  </div>
                </b-dropdown>
              </div>
            </header>

            <template v-if="q.answer !== null">
              <template v-if="JSON.parse(q.answer).string">
                <article
                  class="claims-content"
                  v-if="!isCollapsed(index)"
                  v-html="
                    JSON.parse(q.answer)
                      .string.replace(/(\*\*)|(\*)/g, '')
                      .replace(/---+/g, '<hr>')
                      .replace(/###/g, '<span>')
                      .replace(/##/g, '<span>')
                      .replace(/# /g, '<span>')
                      .replace(/(\r\n|\n|\r)/g, '<br>')
                  "
                ></article>
              </template>

              <template v-else>
              <table v-if="!isCollapsed(index)" class="case-summary mt-4" aria-label="Case Summary Details">
                <thead>
                  <tr>
                    <th v-for="(value, key) in JSON.parse(q.answer).table[0]" :key="key" scope="col">
                      {{ key }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in JSON.parse(q.answer).table" :key="item['Case Citation']">
                    <td v-for="(value, key) in item" :key="key">
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </table>
              </template>
            </template>

            <section class="skeleton-loader mt-4" v-else>
              <div class="skeleton-item skeleton-item-large"></div>
              <div class="skeleton-item skeleton-item-full"></div>
              <div class="skeleton-item skeleton-item-full"></div>
            </section>
          </section>

          <main class="content mt-3">
            <section class="intro">
              <h6 class="title">Build an Argument</h6>
              <hr class="separator" />
            </section>

            <section class="question-section">
              <div 

              class="tabs"
              >
                <!-- <div 
                v-for="(q, index) in questions"
                :key="`questions-${q.id}`"
                :id="`q-${q.id}`"
                class="tab d-flex gap-2 align-items-center"
                > -->
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">Statement:</h6>
                  <button class="button pe-none">
                    <img :src="countryImages[shortJurisdiction]" alt="Country flag" class="img me-2" />
                    <span>{{ jurisdiction }}</span>
                  </button>
                </div>
              </div>
              <p class="question-description">  
                {{ questions[0]?.question || '(NO DATA)' }}
              </p>

              <div>
                <div class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">Uploaded Document Summary</h6>
                  <button class="button" @click="toggleSidebar">
                    <h class="text-link">{{ files.length }} Files Uploaded</h>
                  </button>
                </div>
              </div>
              <!-- <p class="question-description">(NO DATA)</p> -->
              </div>

              <!-- <button class="button"  @click="viewSummary(files)">
                 <h class="text-link"> View Full Summary</h>
              </button> -->
            </section>

            <hr class="separator" />
            <section class="answer-section">
              <div class="answer-header mt-2">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="answer-title">Generated Argument</h6>
                </div>
                <div class="actions">
                  <button class="button me-2" style="border-width: 0px; color: #0E4485 !important;" @click="copy"><img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4042ce02169404fc8f24e2435141f1518abb04892a209162cf916b7ff7de4ac3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                      alt="Copy icon" class="img" />
                    <span>Copy</span>
                  </button>
                  <button @click="ExportDiscussion(discussion)" class="button button-primary">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f2a54da00e522cdf1529d8e0426a02f942a8ec8a8d127f4f56d06f70ef396dd?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                      alt="Export icon" class="img" />
                    <span>Export</span>
                  </button>
                </div>
              </div>
              <p class="answer-text">
                Based on your query and uploaded documents, NeXa has generated the following insights.
              </p>
              <div class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question mt-2">Introduction</h6>
                </div>
              </div>
              <p class="answer-text m-0">
                {{ introduction }}
              </p>
            </section>

            <section class="discussion mt-4">
              <div class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">Discussion:</h6>
                </div>
              </div>
              <div style="background-color: #fafbfc; border-radius: 16px;">
                <quill-editor
                  class="mt-2 quillEditor"
                  v-model="discussion"
                  :options="optionsContent"
                >
                </quill-editor>
                <!-- <div class="d-flex justify-content-end me-4 pb-3">
                  <button class="button me-2" style="background-color: #FFF">
                    <span style="color:#000046; font-size: 14px;">Cancel</span>
                  </button>
                  <button class="button button-primary" style="background-color:#0E4485;">
                    <span style="color: #FFF; font-size: 14px;">Save</span>
                  </button>
                </div> -->
              </div>
              <div class="tabs mt-4">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question mt-2">Conclusion</h6>
                </div>
                <p class="answer-text mt-1">
                  {{ conclusion }}
                </p>
              </div>
            </section>

            <section class="doctorine mt-4">
              <div class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">Doctrines({{ doctrines.length }})</h6>
                </div>
              </div>
              <div v-if="doctrines.length === 0">
                <p class="answer-text mt-2">
                  No Data Available
                </p>
              </div>
              <div v-for="(doctrine, index) in visibleDoctrines" :key="index" class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h class="doctorine-text mt-4">{{ doctrine.doctrine_name }}</h>
                </div>
                <div class="pt-2">
                  <p class="answer-text mt-1">
                    Status: <span class="ms-2" style="color: var(--Status-Green, #52C41A) !important;">{{ doctrine.doctrine_status }}</span>
                  </p>
                  <p class="answer-text" style="margin: 0px !important;">Recent Finalized Cases: </p>
                  <button class="p-0 mt-1">
                    <h class="text-link"><a :href="doctrine.doctrine_case_link" target="_blank">{{ doctrine.doctrine_case }}</a></h>
                  </button>
                  <p class="answer-text mt-1">{{ doctrine.doctrine_case_summary }}</p>

                </div>
              </div>
              
              <div v-if="doctrines.length > 2" class="d-flex justify-content-center align-items-center">
                <button @click="toggleShowAll" class="d-flex align-items-center" style="color: var(--Primary-Blue, #0E4485);">
                  <span class="material-symbols-rounded icon m-2">
                    {{ showAll ? 'remove' : 'add' }}
                  </span>
                  {{ showAll ? 'Show Less' : 'Show More' }}
                </button>
              </div>
            </section>

            <section class="citations mt-4 mb-5 pb-4">
              <div class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">Citations({{ totalCitations }})</h6>
                </div>
              </div>
              <div class="tabs m-3">

                <div v-for="(citation, index) in case_laws" :key="citation.id" class="m-1">
                  <h class="index-link">{{ index }}.  </h>
                  <h class="text-link">
                    <a :href="citation.Link" target="_blank">{{ citation.Name }}</a>
                  </h>
                </div>
                <div v-for="(citation, index) in legislations" :key="citation.id" class="m-1">
                  <h class="index-link ms-1">{{ index }}.  </h>
                  <h class="text-link" style="padding: 5px !important;">
                    <a :href="citation.href" target="_blank">{{ citation.name }}</a>
                  </h>
                </div>
                
              </div>
            </section>
          </main>
          
        </div>
      </div>
    </div>
    <div v-if="!loading" class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar v-if="sidebarCollapsed" @toggle-sidebar="toggleSidebar" :uploadFiles="files"></sidebar>
  </div>
</template>
<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ExportDataPDF } from "../../../store/utils";
import ChatBox from "../../../components/input/ChatBox.vue";
import countryImages from '../../../assets/country/index';
import loader from './deep-research-loader.vue';
import sidebar from '../sidebar.vue';
import usecase from '@/store/usecase.js';
import nexa from '@/store/nexa.js';

export default {
  components: {
    loader,
    sidebar,
    ChatBox,
  },
  data() {
    return {
      loading: true,
      duration: -1,
      interval: null,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      showSummary: false,
      countryImages,
      files: [],
      questions: '',
      question: '',
      answer: '',
      legislations: {},
      case_laws: {},
      discussion: '',
      jurisdiction: '',
      activeTab: 'case_laws',
      followUpQuestion: '',
      collapsedQuestions: [],
      isCopied: {},
      isFetching: false,
      showAll: false,
      isDropdownOpen: [],
      doctrines: [],

      optionsContent: {
        modules: {
          toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            ["bold", "italic", "underline","strike","code-block"],
            [{ list: "bullet" },{ list: "ordered" }],
          ],
        },
      },
    }
  },
  computed: {
    filteredQuestions() {
      return this.questions.slice(1);
    },
    parsedCaseLaws() {
      return Object.values(this.case_laws);
    },
    parsedLegislations() {
      return Object.values(this.legislations);
    },
    totalCitations() {
      return Object.keys(this.case_laws).length + Object.keys(this.legislations).length;
    },
    visibleDoctrines() {
      return this.showAll ? this.doctrines : this.doctrines.slice(0, 2);
    },
    shortJurisdiction() {
      const mapping = {
        'Australia': 'AUS',
        'USA': 'USA',
        'Canada': 'CAN',
        'New Zealand': 'NZ',
        'Singapore': 'SG',
        'Malaysia': 'MY',
        'United Kingdom': 'UK'
      };
      return mapping[this.jurisdiction] || this.jurisdiction;
    }
  },
  methods: {
    toggleDropdown(index, state) {
      this.$set(this.isDropdownOpen, index, state);
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    fetchData(){
      this.refetchAnswers();
    },
    headerTableFollowUpQuestion(q) {
      const table = JSON.parse(q.answer).table;
      const keysSet = new Set();

      table.forEach((item) => {
        Object.keys(item).forEach((key) => {
          keysSet.add(key);
        });
      });

      return Array.from(keysSet).map((key) => {
        const normalizedKey = key.toLowerCase().replace(/ /g, "_");

        return {
          label: key,
          field: normalizedKey,
        };
      });
    },
    filteredTableFollowUpQuestion(q) {
      const table = JSON.parse(q.answer).table;

      return table.map((item) => {
        const rowData = {};
        const headers = this.headerTableFollowUpQuestion(q);

        headers.forEach((header) => {
          const { field, label } = header;

          if (field.includes(".")) {
            const keys = field.split(".");
            rowData[field] = item[keys[0]] ? item[keys[0]][keys[1]] : "";
          } else {
            rowData[field] = item[label] !== undefined ? item[label] : "";
          }
        });

        return rowData;
      });
    },
    exportToPDF(q) {
      const headers = this.headerTableFollowUpQuestion(q).map(header => header.label);
      const data = this.filteredTableFollowUpQuestion(q).map(item => Object.values(item));

      const doc = new jsPDF();
      doc.autoTable({
        head: [headers],
        body: data,
      });
      doc.save('table.pdf');
    },
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;
      const checkAllAnswers = () =>
        this.questions.every((q) => q.answer !== null);

      while (!checkAllAnswers()) {
        const updatedQuestions = await Promise.all(
          this.questions.map(async (element) => {
            if (element.answer == null) {
              try {
                const response = await usecase.GetResult(
                  this.$route.params.id ?? "",
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id,
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                return element;
              } catch (error) {
                return element;
              }
            }
            return element;
          })
        );

        this.questions = updatedQuestions;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
      this.isFetching = false;
    },

    submitQuery(question) {
      const formData = new FormData();
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);

      this.blockUser = true;
      nexa
        .DetailAsk(this.$route.params.id, formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;
          this.refetchAnswers();

          if (responseQuestions && Array.isArray(responseQuestions)) {
            responseQuestions.forEach((q) => {
              const existing = this.questions.find(
                (existing) => existing.id === q.id,
              );
              if (existing) {
                existing.answer = q.answer;
              } else {
                this.questions.push(q);
              }
            });

            this.refetchAnswers();

            if (response.data.completedPercentage === 100) {
              this.blockUser = false;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },
    toggleCollapse(index) {
      const isCollapsedIndex = this.collapsedQuestions.indexOf(index);
      if (isCollapsedIndex >= 0) {
        this.collapsedQuestions.splice(isCollapsedIndex, 1);
      } else {
        this.collapsedQuestions.push(index);
      }
    },
    isCollapsed(index) {
      return this.collapsedQuestions.includes(index);
    },
    viewSummary(files) {

      const fileIds = files.map(file => file.id);
      console.log(fileIds);

      const payload = {
        summarize_doc_ids: fileIds,
      }
      
      usecase.Summary(this.$route.params.id, payload).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
      });
      this.$router.push({ name: 'BuildArgumentSummary' });
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: 'BuildArgument' });
    },
    editQuestion() {
      this.$router.push({ name: 'DeepResearchEdit', params: { id: this.$route.params.id } });
    },
    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    showAttachModal() {
      this.$bvModal.show('attachfile');
    },
    hideAttachModal() {
      this.$bvModal.hide('attachfile');
    },
    copy() {
      const plainText = this.discussion
        .replace(/<br\s*\/?>/gi, '\n') // Replace <br> tags with newlines
        .replace(/<a[^>]*>(.*?)<\/a>/gi, '$1') // Keep the inner text of <a> tags, discarding the tag itself
        .replace(/<\/?span[^>]*>/gi, '') // Remove <span> tags but keep the text inside
        .replace(/^\s+|\s+$/g, '') // Trim any leading or trailing whitespace
        .replace(/\n{3,}/g, '\n\n'); // Reduce multiple newlines to double newlines for cleaner spacing

      navigator.clipboard.writeText(plainText);
      this.$toast.success("Copied as plain text");
    },
    copyAnswer(q, index) {
      let content;

      try {
        const parsedAnswer = JSON.parse(q.answer);
        if (parsedAnswer.string) {
          content = parsedAnswer.string
            .replace(/(\*\*)|(\*)/g, "")
            .replace(/---+/g, "\n---\n")
            .replace(/###/g, "\n## ")
            .replace(/##/g, "\n# ")
            .replace(/# /g, "\n# ")
            .replace(/(\r\n|\n|\r)/g, "\n");
        } else if (parsedAnswer.table) {
          const parsedAnswer = JSON.parse(q.answer);
          const table = parsedAnswer.table
            .map((item) => {
              const result = [];

              for (const [key, value] of Object.entries(item)) {
                result.push(`${key}:\t${value || "[None]"}`);
              }

              return result.join("\n");
            })
            .filter(Boolean)
            .join("\n\n");

          content = table;
        }
      } catch (error) {
        console.error("Unable to parse the answer:", error);
        return;
      }

      navigator.clipboard
        .writeText(content)
        .then(() => {
          if (!this.isCopied[index]) {
            this.$set(this.isCopied, index, true);
          }
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        })
        .finally(() => {
          setTimeout(() => {
            this.$set(this.isCopied, index, false);
          }, 2000);
        });
    },
    ExportData(data, question) {
      const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Follow up Question: ${question}</h1>`;
      let formattedContent =
        `${followUpQuestion}\n<hr style="border:1px solid black;">\n\n` +
        data
          .replace(/(\*\*)|(\*)/g, "")
          .replace(/---+/g, '<hr style="border:1px solid black;">')
          .replace(/### (.*?)\n/g, '<h3 style="font-size: 18px">$1</h3>')
          .replace(/## (.*?)\n/g, '<h2 style= "font-size: 20px">$1</h2>')
          .replace(/# (.*?)\n/g, '<h1 style="font-size: 22px">$1</h1>')
          .replace(/#/g, "")
          .replace(/(\r\n|\n|\r)/g, "<br>")
          .replace(/(\d+)\.\s*(.*?)<br>/g, "<strong>$1. $2</strong><br>")
          .replace(/- (.*?)\n/g, "<li>$1</li>")
          .replace(/<li>(.*?)<\/li>/g, "<ul><li>$1</li></ul>");
      ExportDataPDF(formattedContent);
      this.$toast.success("Successfully downloaded PDF");
    },
    ExportDiscussion(data) {
        data
          .replace(/(\*\*)|(\*)/g, "")
          .replace(/---+/g, '<hr style="border:1px solid black;">')
          .replace(/### (.*?)\n/g, '<h3 style="font-size: 18px">$1</h3>')
          .replace(/## (.*?)\n/g, '<h2 style= "font-size: 20px">$1</h2>')
          .replace(/# (.*?)\n/g, '<h1 style="font-size: 22px">$1</h1>')
          .replace(/#/g, "")
          .replace(/(\r\n|\n|\r)/g, "<br>")
          .replace(/(\d+)\.\s*(.*?)<br>/g, "<strong>$1. $2</strong><br>")
          .replace(/- (.*?)\n/g, "<li>$1</li>")
          .replace(/<li>(.*?)<\/li>/g, "<ul><li>$1</li></ul>");
      ExportDataPDF(data);
      this.$toast.success("Successfully downloaded PDF");
    },
    handleSubmit() {
      if (this.followUpQuestion.length > 0) {
        const payload = {
          language: 'en',
          location_id: 1,
          question: this.followUpQuestion
        };

        nexa.DetailAsk(this.$route.params.id, payload)
          .then((response) => {
            const newResponse = response.data.data;
            console.log(newResponse);
            this.followUpQuestion = '';
            this.$toast.success("Successful");
          })
          .catch((error) => {
            console.error("Error sending question:", error);
          });
      }
    },
    formatDateTime(date) {
      return new Date(date).toISOString();
    },
    getTimeDifference(date) {
      const now = new Date();
      const createdAt = new Date(date);
      const diffInMinutes = Math.floor((now - createdAt) / (1000 * 60));

      if (diffInMinutes < 60) {
        return `${diffInMinutes} m`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        return `${diffInHours} h`;
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    usecase.GetResult(this.$route.params.id ?? '').then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.loading = false;
      }

      if (DATA.completedPerCentage < 100) {
        if (DATA.completedPerCentage > 0) this.duration = DATA.completedPerCentage;

        this.interval = setInterval(() => {
          usecase.GetResult(this.$route.params.id).then((res) => {
            if (res.data.data.completedPerCentage >= 100) {
              this.duration = res.data.data.completedPerCentage;
              clearInterval(this.interval);
              location.reload();
            } else if (res.data.data.completedPerCentage > 0) {
              this.duration = res.data.data.completedPerCentage;
            }
          });
        }, 10000);
      } else {
        this.duration = 100;
        this.question = DATA.questions[0].question;
        this.answer = DATA.questions[0].answer;
        this.jurisdiction = Object.keys(JSON.parse(DATA.jurisdiction))[0];
        this.legislations = JSON.parse(DATA.values.find(el => el.key === 'nexa_legislations').value);
        this.case_laws = JSON.parse(DATA.values.find(el => el.key === 'nexa_caselaws').value);
        this.files = DATA.questionDocuments;
        this.doctrines = JSON.parse(DATA.values.find(el => el.key === 'nexa_doctrines').value);
        
        this.discussion = JSON.parse(DATA.values.find(el => el.key === 'nexa_discussion').value)
          .replace(/\\n/g, '\n') // Convert literal '\n' to actual newline
          .replace(/^\s*##\s*(.*?)\s*$/gm, '<br><span style="font-weight:500;font-size:15px;color:#0E4485">$1</span><br>') // Make lines starting with ### or #### bold
          .replace(/^\s*#\s*(.*?)\s*$/gm, '<br><span class="h6" style="color:#0E4485">$1</span><br>') // Make lines starting with ### or #### bold
          .replace(/\n/g, '<br>') // Replace actual newline characters with <br>
          .replace(/\n+/g, '<br>') // Replace multiple actual newlines with a single <br>
          .replace(/\*\*(.*?)\*\*/g, '<span style="font-weight:500;color:#0E4485">$1</span>') // Convert text between ** to <b>
          .replace(/\*/g, '') // Remove any remaining asterisks (single)
          .replace(/\\/g, '<br>') // Replace backslashes with <br>;
        // this.doctrines = JSON.parse(DATA.values.find(el => el.key === 'nexa_doctrines').value);
        this.introduction = JSON.parse(DATA.values.find(el => el.key === 'nexa_introduction').value);
        this.conclusion = JSON.parse(DATA.values.find(el => el.key === 'nexa_conclusion').value);
        this.nexa = JSON.parse(DATA.values.find(el => el.key === 'nexa_discussion').value);
        this.file_value = JSON.parse(DATA.values.find(el => el.key === 'nexa_files').value);
        this.questions = DATA.questions || [];

        const hasNullValue = this.questions.some((q) => q.answer === null);

        if (hasNullValue) {
          this.refetchAnswers();
        }

        this.collapsedQuestions = this.questions.map((_, index) => index);

        // nexa.AllResult().then(response => { 
        //   const DATA = response.data.data.data;
        //   this.questions = DATA.map(item => {
        //     return {
        //       id: item.id,
        //       title: item.title,
        //       fileCount: item.file_uploaded_count,
        //       createdAt: item.created_at,
        //     };
        //   });
        // }).catch(error => {
        //   console.error('Error fetching AllResult:', error);
        // });
      }
    });
  }

}
</script>

<style scoped>
.case-summary {
    font-size: 0.85em; /* Reduce font size */
    width: 100%;
    border-collapse: collapse; /* Reduce spacing between cells */
  }
  .case-summary th,
  .case-summary td {
    padding: 4px 8px; /* Reduce cell padding */
    border: 1px solid #ddd; /* Add a light border */
    text-align: left;
  }
  .case-summary th {
    background-color: #f2f2f2; /* Add a background color to headers */
    font-weight: bold;
  }

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header{
      display: flex;
      justify-content: space-between;
      height: 68px;
      background: #fff;
      /* padding: 16px 20px; */
      align-items: center;
    }
    
    .breadcrumb {
      display: flex;
      align-items: center;
      margin: auto 0;
    }
    .breadcrumb-text{
      color: var(--Neutral-Dark-Grey, #86888D);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .open_sidebar {
      display: flex;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: var(--Primary-Blue, #0E4485);
    }

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
</style>
<style scoped>
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}

.intro,
.question-section,
.answer-section,
.discussion,
.doctorine,
.citations,
.legal-references {
  max-width: 100%;
}

.quillEditor .ql-toolbar.ql-snow{
border-radius: 8px 0px !important;
border-bottom: 1px solid #D1D2D5 !important;
background: #F2F3F3 !important;
}

.legal-references {
  margin-bottom: 5rem;
}

.title {
  color: var(--Primary-Blue, #0e4485) !important;
  font: 500 16px/28px Poppins, sans-serif;
}

.separator {
  background: #f2f3f3;
  height: 1px;
  width: 100%;
}

.question-section .tabs,
.answer-section .answer-header, 
.legal-references {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 25px
}

.result-tabs {
  display: flex;
  width: 100%;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  align-items: center;
}

.question .button,
.actions .button,
.tab {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  border-radius: 4px;
}

h6.title.answer-title.case-title {
  color: var(--Primary-Blue, #0e4485) !important;
  margin: 0px;
}

.img {
  aspect-ratio: 1/1;
  width: 18px;
  object-fit: contain;
}

.question-description,
.answer-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font: 400 14px/24px Poppins, sans-serif;
}

.doctorine-text{
  color: var(--Neutral-Dark-Grey, #86888D);

  /* Button */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-link{
  color: var(--Status-Blue, #1890FF);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  
}

.index-link{
  color: var(--Status-Blue, #1890FF);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.answer-title,
.reference-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
  line-height: 28px;
}

.actions .button {
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-White, #fff);
  color: var(--Primary-Dark-Blue, #000046) !important;
}

.actions .button-primary {
  background: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff) !important;
}

.actions .button,
.actions .button-primary {
  padding: 2px 8px;
}

.details .detail {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
}

.detail-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
}

.detail-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.link {
  color: var(--Status-Blue, #1890ff) !important;
}

.results {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
}

.result-tabs .tab {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: var(--Neutral-Dark-Grey, #86888D);
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 0px !important;
}

.result-tabs .tab.active {
  border-bottom: 2px solid var(--Primary-Blue, #0e4485);
  color: var(--Primary-Blue, #0e4485) !important;
}

.case-result {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  padding: 20px 16px;
  margin-top: 20px;
}

.case-title {
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 500;
}

.case-details .meta {
  display: flex;
  align-items: center;
}

.case-details .meta img {
  margin-right: 4px;
}

.case-overview,
.case-analysis p {
  font-size: 14px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  line-height: 24px;
}

.relevancy {
  display: flex;
  align-items: center;
  color: var(--Status-Green, #52c41a) !important;
}

.relevancy img {
  margin-right: 4px;
}

.actions {
  display: flex;
  gap: 2px;
}
</style>

<style scoped>
.follow-up-question {
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-Blue, #0e4485);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 10px 0 rgba(14, 68, 133, 0.6);
  z-index: 10;
  display: flex;
  width: 74%;
  gap: 8px;
  flex-wrap: wrap;
  padding: 12px 20px;
  align-items: center;
  position: fixed;
  bottom: 20px;
}

.input-field {
  align-self: stretch;
  min-width: 240px;
  flex: 1;
  padding: 10px 16px 10px 0;
  font: 400 16px Poppins, sans-serif;
  border: none;
  outline: none;
  background: transparent;
}

.follow-up-actions {
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: start;
  height: 100%;
  width: 56px;
}

.submit-button {
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.submit-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {

  .follow-up-question,
  .input-field {
    max-width: 100%;
  }
}
</style>


<style>
#dropdown-right .dropdown-toggle:focus,
#dropdown-right .dropdown-toggle:active,
#dropdown-right .dropdown-toggle.show,
#dropdown-right .dropdown-menu {
  outline: none;
  box-shadow: none;
}

#dropdown-right .dropdown-item:active,
#dropdown-right .dropdown-item:focus{
  
  color: black !important;
  background-color: transparent !important;
}


.case-summary {
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.case-summary th {
  padding: 9px 20px 9px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: 1px solid var(--Neutral-Dark-Grey, #86888D);
  border-radius: 8px;
}

.case-summary td {
  padding: 9px 4px 9px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Dark-Grey, #86888D);
  border-bottom: 1px solid var(--Neutral-Dark-Grey, #86888D) !important;
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}

.claims-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  /* flex-wrap: wrap; */
}

.title-group {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 8px;

  line-height: 28px;
}
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}
.primary-title {
  color: #0e4485;
  font-family: Poppins;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.secondary-title {
  color: #383a3e;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.dropdown-text{
  color: var(--Neutral-Black, #383A3E);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.icon {
  width: 18px;
  object-fit: contain;
}

.action-icon {
  width: 18px;
  height: 18px;
}

.claims-content {
  margin: 32px 0;

  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.claims-table {
  border: 1px solid #86888d;
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: flex;
  background: #f2f3f3;
  border-bottom: 1px solid #86888d;
}

.column-title {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #383a3e;
}

.column-title:first-child {
  border-right: 1px solid #86888d;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #86888d;
}

.row-label {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  border-right: 1px solid #86888d;
}

.row-content {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
}

.wrapper-chatbox {
  position: fixed;
  right: 2rem;

  width: 80%;
  z-index: 30;
  bottom: 2rem;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.skeleton-item {
  border-radius: 12px;
  background-color: rgba(243, 243, 243, 1);
  min-height: 28px;
}

.skeleton-item-large {
  width: 535px;
  max-width: 100%;
}

.skeleton-item-full {
  width: 100%;
  margin-top: 8px;
}

</style>
